import React from 'react'
import { NavLink} from 'react-router-dom';
import PageBreadcrumbs from './../components/PageBreadcrumbs';
import PageTitle from './../components/PageTitle';
import { useEffect, useState } from 'react';
import { API_OOPGet} from '../api/oop';
import { API_movecourse} from '../api/move';
import { API_GetDocument} from '../api/getdocument';
import { Table,Icon,Menu,Button,notification,Select,Modal,Form} from 'antd';
import { API_TemplatedownloadFile, API_downloadFile,API_TemplatedownloadFile2 } from './../api/file';
import {spandata,PageTitle2} from './../api/addon';
import  {Api_Save_Status} from './../api/task';
import { API_Online } from '../api/getheader';
import getColumnSearchProps from './../components/getColumnSearch'
const routes = [{ label: 'Главная', path: '/' }, { label: 'ООП', path: '/task' }]
//const { Option } = Select;
var first = true

const TaskOOP = (props) => {
    const getOnline = () => {
        API_Online().then(response => {
            if (parseInt(response.Status)===0) {
                notification.error({ message: `Ваша сессия истекла`, description: "Нужно перезайти" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
            if (parseInt(response.Status)===2) {
                notification.error({ message: `Авторизация успешна, но доступ закрыт`, description: "Ваши данные не найдены в рабочих группах модуля \"Аккредитации\" , обратитесь в директору/декану вашего учебного подразделения" });
                localStorage.removeItem('accessToken')
                props.history.push('/login')
                // время истекло должно выкинуть
            }
        }).catch(function(error) {
            try {
                notification.error({ message: `Ошибка 1`, description: "неопознанная ошибка" });
                
            } catch {
                notification.error({ message: `Ошибка 2`, description: "неопознанная ошибка" });
            }
        });;
    };
    useEffect(() => {
            getOnline();
    // eslint-disable-next-line            
    }, []);
    const [loading, setLoading] = useState(false);
    const [showmodal, setshowmodal] = useState(false);
    const [setid,setIDfile] = useState(0);
    const [setStatus,setIDStatus]= useState(0);
    const [setoption,choiceoption]=useState(0);
    const [pageName, setPageName] = useState('Загрузка документов');
    const [data, GetDocument] = useState([]);
    const [role,setrole]=useState(0);
 // Основная штука которая определяет доступ к разделам по авторизации

    useEffect(() => {
        const valueid = parseInt(props.match.params.id, 10);
        const course = parseInt(props.match.params.course, 10);
        const form = parseInt(props.match.params.form, 10);
        if ((valueid,course,form)) {
           getHeader(valueid,course,form);
            
        }
    }, [props.match.params.id,props.match.params.course,props.match.params.form]);
    

    function getHeader(valueid,course,form) {
        API_OOPGet(valueid,course,form).then(response => {
                if (response[0].fgos===undefined) {
                    setPageName(`Ошибка доступа`)
                    notification.error({ message: `У вас нет доступа`, description: `В системе показывает только разделы где есть набор`});

                } else {    
                setrole(response[0].role); // ставим роль
                setPageName(`${response[0].fgos},${response[0].code},${response[0].fak} ${response[0].napr} ${response[0].profile}, ${response[0].course} курс, ${response[0].form} форма`)
                }
        })
        .catch(error => {
            try {
                setPageName(`Ошибка доступа`)
                notification.error({ message: `У вас нет доступа или раздел отсутствует`, description: error.response });
            } catch {
            }
        })
    };
    const roles=['','Эксперт ДОКО','Руководитель экспертной группы КУМС',
    'Секретарь экспертной группы КУМС',
    'Эксперт КУМС',
    'Руководитель рабочей группы',
    'Член рабочей группы']   
// eslint-disable-next-line
const onChange =(idFile,status) =>{
    if (role>0) {
    setIDStatus(status);
    setIDfile(idFile);
    setshowmodal(true);
    } else {
        notification.error({ message: `У вас нет доступа`, description: `Статусы могут ставить Руководитель экспертной группы КУМС, секретарь и эксперты КУМС, а также сотрудники ДОКО`});
    }
    
}
// 1- На доработку 2 - Принято  3  - На Печать
/*
const variants = [[{id:1,name:"На доработку"},{id:3,name:"На печать"},{id:2,name:"Принято"}],
[{id:3,name:"На печать"},{id:2,name:"Принято"}], // 1 на доработку
[{id:1,name:"На доработку"},{id:3,name:"На печать"}], // 2 принято
[{id:2,name:"Принято"},{id:1,name:"На доработку"}]]; //3 На печать*/
const variants = [[{id:1,name:"Показать"},{id:2,name:"Спрятать"}]];
const handleOk = e => {
  // тут должен идти вызов api на сохранение
 if ((setid>0)&&(setoption>0)) {
     Api_Save_Status(setid,setoption).then(response=> {
        const valueid = parseInt(props.match.params.id, 10);
        const course = parseInt(props.match.params.course, 10);
        const form = parseInt(props.match.params.form, 10);
        API_GetDocument(valueid,course,form).then(data => {
            let index = 0;
            GetDocument(
                data.map(s => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        });
         
     })
     setshowmodal(false);
 } else {
    notification.error({ message: `Статус должен быть выбран`, description: "Выберите статус" });
 }
  
};

const handleCancel = e => {
  setshowmodal(false);
};

// загрузка шаблона - по идее еще должна быть загрузка самого файла
const downloadTemplateFile = (folder,lvl,idFile) => {
    setLoading(true);
    API_TemplatedownloadFile(folder,lvl,idFile)
        .then(response => {
            notification.info({ message: `Файл загружается` });
        })
        .catch(error => {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        })
        .finally(() => setLoading(false));
};
// загрузка шаблона checklist

const downloadTemplateFile2 = (folder,lvl,idFile) => {
    setLoading(true);
    API_TemplatedownloadFile2(folder,lvl,idFile)
        .then(response => {
            notification.info({ message: `Файл загружается` });
        })
        .catch(error => {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        })
        .finally(() => setLoading(false));
};

const downloadFile = (idFile,namefile) => {
    setLoading(true);
    API_downloadFile(idFile,namefile)
        .then(response => {
            notification.info({ message: `Файл загружается: `});
        })
        .catch(error => {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        })
        .finally(() => setLoading(false));
};
const columns = [
    { title: '',
    key: 'files',
    render: row => {
        if (row.files===undefined)  {
            return  <Icon type="minus-circle" style={{ color: 'red' }} /> 
        }
        }
    },
    /*
    render: row => {
        
        if (parseInt(row.files)===0)  {
            return  <Icon type="minus-circle" style={{ color: 'red' }} /> 
        }   else if (parseInt(row.files)<10000) {
            return <Icon type="check-circle" style={{ color: 'green' }} />
        }
     },*/
     
    { title: 'Наименование документа', 
    ...getColumnSearchProps('qname','Наименование'),
    render: row => {
       if (row.idtype===undefined)  {
            return  spandata(row.qname,row.design)
       }   else  {
        return  spandata(row.qname,3)
       }
    }, key: 'qname' },
    { title: 'Форма, шаблон',  key: 'id',
    render: row => {
      if (row.templatename!==undefined)
      return   <Button icon="download" onClick={() => downloadTemplateFile(row.folder,parseInt(row.lvl, 10),row.templatename)} loading={loading}>Шаблон</Button> 
  },
   },
   { title: 'Загрузить документ',  key: 'docname',
   render: row => {
    if (role>0) {   
    if ((props.match.params.course==="0")&&(props.match.params.form==="0")&&(row.idtype===1)) {
       if (row.code!==undefined) {
       return <Button icon="upload" onClick={() => props.history.push(`/setdocument/${valueid}/${course}/${form}/${row.id}`)} type="primary"  />
       }
    } else {
        if ((row.code!==undefined)&&(row.idtype===undefined)&&(props.match.params.course!=="0")&&(props.match.params.form!=="0")) {
            
            return <Button icon="upload" onClick={() => props.history.push(`/setdocument/${valueid}/${course}/${form}/${row.id}`)} type="primary"  />
            }
    }
    }
   },
},
//  в случае размещения ссылки, row.fileid ищет поле url и оттуда запускает значение. закачки
   { title: 'Скачать документ',  key: 'fileid',
    render: row => {
        if (row.fileid>0) {
            return <Button icon="download" onClick={() => downloadFile(row.fileid,row.docname)} loading={loading} />
        }
    }
    },
  
    { title: 'Описание документа', key: 'comment',
    ...getColumnSearchProps('comment','Описание'),
    render: row => {
        if (row.fileid>0) {
          if   (row.url===undefined) 
            return spandata(row.comment+"|"+row.fileid,12) 
         else 
            return spandata(row.comment+' (ссылка на файл ) '+row.url) 
        }
    }

},
    { title: '+ курс - id не меняется', key: 'fio' ,
// если есть понятие что это внешнняя ссылка то не выводит
    render: row => {
        if ((row.fileid>0)&&(row.url===undefined))
                    return  (
                       
                    <Button onClick={() => 
                        Modal.confirm({
                            title: 'Вы уверены?',
                            centered: true,
                            onOk: () => {
                                setLoading(true);
                                window.location.reload();
                                API_movecourse(row.fileid,row.course+1).catch(error => {
                                        notification.error({ message: `Ошибка`, description: error });
                                })
                                .finally(() => setLoading(false));
                        }
                            })
                        } type="primary">{row.course+1}</Button>
                        )
            }

},
    { title: 'Дата последней загрузки', dataIndex: 'dateupload', key: 'dateupload' },
    
    { title: 'Статус документа', key: 'status', 
    render: row => {
        if ((row.status===undefined)&&(row.fileid>0)&&(row.files!==10000)) {
            if (row.url>0) {
                return (<p>Показать(ссылка)</p>)
            } else
            {    
                return (<Button type="link" onClick={() => onChange(row.fileid,0)}>Показать</Button>)
            }
        }
        if (row.status===2) {
            if (row.url>0) {
                return (<p>Спрятан</p>)
            } else
            {    
				return (<Button type="link" icon="eye" onClick={() => onChange(row.fileid,0)}>Спрятан</Button>)
                //return (<Button type="danger" icon="close" onClick={() => onChange(row.fileid,row.status)}>Спрятан</Button>)
            }
        }
		
        if (row.status===1) {
            if (row.url>0) {
                return (<p>Показать(ссылка)</p>)
            } else {
                return (<Button type="link" onClick={() => onChange(row.fileid,0)}>Показать</Button>)
            }
        }
		/*
        if (row.status===3) {
            if (row.url>0) {
                return (<p>На печать(ссылка)</p>)
            } else {
                return (<Button type="primary" icon="printer" onClick={() => onChange(row.fileid,row.status)}>На печать</Button>)
            }
        }*/
    }
    
     },
  ];


if (role===100) {
    if (first) {
        routes.push({ label: 'Раздел эксперта', path: '/expertdocs' })    
        first=false;
    }
columns.push(
{ 
    title: 'Чеклист',
    key: 'checklist',
render: row => {
 if ((row.code!==undefined)&&(row.idtype===undefined)&&(props.match.params.course!=="0")&&(props.match.params.form!=="0")&&(row.fileid>0)&&(row.files!==10000)) {
     return <Button icon="download" onClick={() => downloadTemplateFile2(row.folder,parseInt(row.lvl, 10),row.id)} loading={loading}>Чеклист</Button>             
     }
 }
});
}

    
    

    // документы 
    
    
    useEffect(() => {
        const valueid = parseInt(props.match.params.id, 10);
        const course = parseInt(props.match.params.course, 10);
        const form = parseInt(props.match.params.form, 10);
        API_GetDocument(valueid,course,form).then(data => {
            let index = 0;
            GetDocument(
                data.map(s => {
                    index++;
                    return {
                        ...s,
                        rowNumber: index,
                    };
                })
            );
        });
    }, [props.match.params.id,props.match.params.course,props.match.params.form]);

    const valueid = parseInt(props.match.params.id, 10);
    const course = parseInt(props.match.params.course, 10);
    const form = parseInt(props.match.params.form, 10);


    const login=localStorage.getItem('login');
    const setlvl=login.substring(0,2)
    var bak = false
    var mag = false
    var spec = false
    var spo = false
    var ord = false
    var asp = false
    if (setlvl==="03") {
        bak=false;
        mag = true;
        spec = true;
        spo = true;
        ord = true;
        asp = true;
    }
    if (setlvl==="04") {
        bak=true;
        mag = false;
        spec = true;
        spo = true;
        ord = true;
        asp = true;
    }
    if (setlvl==="05") {
        bak=false;
        mag = true;
        spec = false;
        spo = true;
        ord = true;
        asp = true;
    }
    if (setlvl==="06") {
        bak=false;
        mag = true;
        spec = true;
        spo = true;
        ord = true;
        asp = false;
    }
    if (setlvl==="08") {
        bak=true;
        mag = true;
        spec = true;
        spo = true;
        ord = false;
        asp = true;
    }
    if (setlvl==="02") {
        bak=true;
        mag = true;
        spec = true;
        spo = false;
        ord = true;
        asp = true;
    }

    if (pageName!=='Ошибка доступа') {
    return (
        
        <>
            Ваша роль - {spandata(roles[role],11)}
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Основные профессиональные образовательные программы" />
            <Menu
                            mode="horizontal"
                            style={{ height: '100%' }}
                            activeKey={props.history.location.pathname}
                            selectedKeys={[props.history.location.pathname]}
                        >
                          <Menu.Item key="bak" disabled={bak}>
                                <NavLink to="/task/03">
                                    Бакалавриат
                                </NavLink>
                            </Menu.Item>
                             
                            <Menu.Item key="/spec" disabled={spec}>
                                <NavLink to="/task/05">
                                    Специалитет
                                </NavLink>
                            </Menu.Item>

                            <Menu.Item key="/mag" disabled={mag}>
                                <NavLink to="/task/04">
                                    Магистратура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/asp" disabled={asp}>
                                <NavLink to="/task/06">
                                    Аспирантура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/ord" disabled={ord}>
                                <NavLink to="/task/08">
                                    Ординатура
                                </NavLink>
                            </Menu.Item>
                            <Menu.Item key="/spo" disabled={spo}>
                                <NavLink to="/task/02">
                                    СПО
                                </NavLink>
                            </Menu.Item>    
                        </Menu>
            <PageTitle2 title={pageName} />  
                      
            <Table columns={columns} dataSource={data} bordered size="small" pagination={false}
                rowKey ={record => record.rowNumber} 
             
                />
      <Modal
        title="Измените статуc"
        visible={showmodal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
          {/*QuestionSingle(setid)*/}
          <Form style={{ width: 600, margin: '0 auto' }}>
        <Form.Item style={{ marginBottom: 5 }}>
            <Select onChange={choiceoption} style={{ width: 200 }}>

            {variants[setStatus].map(result => (
                            <Select.Option  value={result.id} key={result.id}>
                                {result.name}
                            </Select.Option>
                        ))}
          </Select>
            
         </Form.Item>
         </Form> 
      </Modal>          
        </>
    )
}
else {
    return (
        
        <>
            Ваша роль - {spandata(roles[role],11)}
            <PageBreadcrumbs routes={routes} />
            <PageTitle title="Основные профессиональные образовательные программы" />
            <PageTitle2 title={pageName} />  
                
        </>
    )
}
}

export default TaskOOP
